import React, {useContext} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { AppContext } from '../contexts/App';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Meta from '../components/Meta';
import MetaData from '../data/MetaData';
import LoginDialog from "../dialogs/LoginDialog";
import ForgotPasswordDialog from "../dialogs/ForgotPasswordDialog";
import RegisterDialog from "../dialogs/RegisterDialog";

const Layout = () => {
  const location = useLocation();
  const [appState, setAppState] = useContext(AppContext);
  const metaData = MetaData(location.pathname);
  const [
    metaTitle,
    setMetaTitle,
    metaDescription,
    setMetaDescription
  ] = Meta();

  const onCloseDialog = (dialog) => {
    appState.setDialogState(dialog, false);

    setAppState({ ...appState });
  }

  useEffect(() => {
    // Update Meta Title
    if (undefined === metaTitle ||
      0 === metaTitle.length || (
        0 !== metaData?.title.length &&
        metaTitle !== metaData?.title
      )) {
      setMetaTitle(metaData?.title);
    }

    // Update Meta Description
    if (undefined === metaDescription ||
      0 === metaDescription.length || (
        0 !== metaData?.description.length &&
        metaDescription !== metaData?.description
      )) {
      setMetaDescription(metaData?.description);
    }
  });

  return (
    <ThemeProvider
      breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
      minBreakpoint="xs"
    >
      {appState.getDialogState('login') ? <LoginDialog
        closeDialog={() => onCloseDialog('login')}
      /> : ''}
      {appState.getDialogState('recover') ? <ForgotPasswordDialog
        closeDialog={() => onCloseDialog('recover')}
      /> : ''}
      {appState.getDialogState('register') ? <RegisterDialog
        closeDialog={() => onCloseDialog('register')}
      /> : ''}

      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />
    </ThemeProvider>
  )
};

export default Layout;
