import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Container, Nav, Navbar, NavDropdown, Offcanvas, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/App';
import { AccountContext } from '../../contexts/Account';
import Image from '../../components/Image';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import InitAccountStateData from '../../data/InitAccountStateData';

import styles from './styles.module.scss';

const MainNav = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery('(max-width: 991px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [appState, setAppState] = useContext(AppContext);
  const [account, dispatchAccount] = useContext(AccountContext);
  const [useMobileMenu, setUseMobileMenu] = useState(false);

  useEffect(() => {
    if (isTablet === useMobileMenu) return;

    setUseMobileMenu(isTablet);
  }, [isTablet, useMobileMenu]);

  const logout = () => {
    dispatchAccount(InitAccountStateData);

    navigate('/');
  };

  const openLogin = () => {
    appState.setDialogState('login', true);
    setAppState({ ...appState });
  };

  const openRegister = () => {
    appState.setDialogState('register', true);
    setAppState({ ...appState });
  };

  return <>
    <Navbar
      id={'header'}
      key={'main-nav'}
      expand={!useMobileMenu}
      sticky="top"
      className={styles.navbar + ' primary pt-0 pb-0 ' + (!useMobileMenu ? 'd-none d-lg-block' : 'd-block d-lg-none') }
    >
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
        <Image src="images/logo_main" type={'svg'} width={238} height={64} alt="Only Amour" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`main-menu-nav`} />
        <Navbar.Offcanvas
          id={`main-menu-nav`}
          aria-labelledby={`offCanvasNavbarLabel-expand`}
          placement="end"
          className={styles.offCanvas}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offCanvasNavbarLabel-expand`} className="w-75">
              <Row>
                <Col>
                  <Image src="images/icon" type={'svg'} width={72} height={60} alt="Only Amour" />
                </Col>
                <Col>
                  <div className="fs-4 mt-3 lh-1 text-tertiary">Only Amour</div>
                </Col>
              </Row>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className={styles.navLinks + (!useMobileMenu ? ' align-items-center ' : ' align-items-start ') + ' justify-content-end flex-grow-1 pe-3'}>
              {!account ? (
                <Nav.Link as={NavLink} to="#">
                  <FontAwesomeIcon icon="spinner" className="fa-spin me-2"/>
                </Nav.Link>
              ) : account.user ? (
                <NavDropdown
                  key={`nav-account-expand`}
                  id={`nav-account-expand`}
                  title={<span className={'me-1'}>
                    {account.user.image ? <img src={account.user.image} height={30} alt="Account Avatar" className={styles.userImg} /> : <FontAwesomeIcon icon={'user'} className={'me-2'} />}
                    {!isMobile ? account.user.forename + ' ' + account.user.surname : 'My Account'}
                  </span>}
                  align="end"
                >
                  <NavDropdown.Item key={'manage-account'} as={NavLink} className={'d-flex'} to="/account/manage">
                    <FontAwesomeIcon icon="user-edit" className="text-tertiary me-2" />
                    <span className={'d-flex'}>Manage Account</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item key={'my-workspaces'} as={NavLink} className={'d-flex'} to="/account/workspaces">
                    <FontAwesomeIcon icon="th-large" className="text-tertiary me-2" />
                    <span className={'d-flex me-3'}>Edit Profile</span>
                    <Badge className="ms-auto align-self-center">
                      {account.user.workspaces.length > 99 ? '100+' : account.user.workspaces.length}
                    </Badge>
                  </NavDropdown.Item>
                  <NavDropdown.Divider key={'account-divider-2'} />
                  <NavDropdown.Item key={'logout'} onClick={() => logout()}>
                    <FontAwesomeIcon icon="sign-out" className="text-tertiary me-2" />
                    <span>Logout</span>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : <>
                <Nav.Link onClick={openLogin}>
                  <Button size={'sm'} variant="tertiary" className={'me-3'}>
                    <FontAwesomeIcon icon="sign-in" className="me-2" />
                    {!isMobile ? 'Login' : ''}
                  </Button>
                </Nav.Link>
                <Nav.Link onClick={openRegister}>
                  <Button size={'sm'} variant="tertiary">
                    <FontAwesomeIcon icon="user-edit" className="me-1" />
                    {!isMobile ? 'Register' : ''}
                  </Button>
                </Nav.Link>
              </>}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </>;
};

export default MainNav;
