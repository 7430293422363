import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useComponentVisible from '../../../hooks/useComponentVisible';
import FontAwesomeIcon from '../../../FontAwesomeIcon';

import styles from './styles.module.scss';

const TextSearchField = ({
  id,
  value,
  selection,
  choices = [],
  placeholder,
  charLimit = 0,
  onChange,
  onSelection,
  readOnly,
  required
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onChoice = (e, choice) => {
    // Avoid parent click
    e.stopPropagation();

    onSelection(id, choice);
    onChange(id, choice.value);
    setIsComponentVisible(false);
  };

  const onText = (e, value) => {
    onChange(id, value);

    setIsComponentVisible(0 < value.length);
  };

  return <div ref={ref} className={styles.container}>
    <Row>
      <Col xs={11}>
        <div className={styles.holder}>
          <div className={styles.input}>
            <div
              onKeyUp={(e) => onText(value + e.code)}
              style={{textIndent:'-10000px',height: 0}}
            >
              {0 < value.length ? value : placeholder}
            </div>
            <input
              id={'search_' + id + '_edhfk'}
              name={'search_' + id + '_dfghgfds'}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onText(id, e.target.value)}
              className={0 < value.length ? styles.hasValue : ''}
              autoComplete={'off'}
              data-lpignore={'true'}
              onClick={(e) => e.preventDefault()}
            />
            {charLimit ? <div className={styles.charLimit}>
              {t('tool_ui.data_entry.characters')} {value.toString().length}/{charLimit}
            </div> : ''}
          </div>
          <div className={`${styles.choices} ${isComponentVisible ? styles.open : ''}`}>
            {choices.filter(
              c => c.value.toLowerCase().includes(value.toLowerCase())
            ).map((choice, idx) => <div
              key={idx}
              onClick={(e) => onChoice(e, choice)}
              className={`${styles.choice} ${'disable' in choice && choice.disable ? styles.disabled : ''}`}
            >
              {choice.value}
            </div>)}
          </div>
        </div>
      </Col>
      <Col xs={1}>
        {selection && charLimit < value.length ? <FontAwesomeIcon
          name={'times'}
          width={16}
          height={16}
          className={`${styles.icon} ${styles.error}`}
        /> : required || (selection && 0 < value.length) ? <FontAwesomeIcon
          icon={0 < (selection && 0 < value.length) ? 'check' : 'times'}
          className={`${styles.icon} ${0 < (selection && 0 < value.length) ? styles.tick : styles.required}`}
        /> : ''}
      </Col>
    </Row>
  </div>;
};

export default TextSearchField;
