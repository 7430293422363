import { AppProvider } from '../contexts/App';
import { AccountProvider } from '../contexts/Account';

export default function Providers({ children }) {
  return <AccountProvider>
    <AppProvider>
      {children}
    </AppProvider>
  </AccountProvider>;
}
