import React, { useRef, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../contexts/App';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import FontAwesomeIcon from '../FontAwesomeIcon';
import TextSearchField from '../Form/Fields/TextSearchField';
import Api from '../../Api';

import styles from './styles.module.scss';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const hasLoaded = useRef(false);
  const [locationSearch, setLocationSearch] = useState('');
  const [appState, setAppState] = useContext(AppContext);
  const [successMessage, setSuccessMessage] = useState(null);
  const [locations, setLocations] = useState([]);
  const [data, setData] = useState({
    orientation: '',
    looking_for: '',
    dob: '',
    dob_year: '',
    dob_month: '',
    dob_day: '',
    location: '',
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    confirm: false,
  });
  const [errorFields, setErrorFields] = useState([]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;

    Api.Location.getByCountry('United Kingdom').then((response) => {
      let locations = [];

      response.data.forEach(location => {
        if (0 < location.county.length && 0 < location.city.length) {
          locations.push({
            key: location.id,
            value: `${location.county} - ${location.city}`,
            disable: false
          });
        }
      });

      setLocations(locations);
    });
  }, []);

  const validateFields = () => new Promise((resolve, reject) => {
    Promise.all(Object.keys(data).map((key) => {
      return validateField(key, data[key]).then(() => true).catch(() => false);
    })).then((result) => result.includes(false) ? reject() : resolve());
  });

  const calculateAge = (dob) => {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const openLogin = () => {
    appState.setDialogState('login', true);
    appState.setDialogState('register', false);

    setAppState({ ...appState });
  };

  const validateField = (field, value) => new Promise((resolve, reject) => {
    switch (field) {
      case 'orientation':
        if (![
          'Man->Woman',
          'Woman->Man',
          'Man->Man',
          'Woman->Woman',
        ].includes(value)) {
          errorFields.push({
            key: 'orientation',
            message: 'Please select an option.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'dob_day':
      case 'dob_month':
      case 'dob_year':
        if (0 < data.dob_year.length && 0 < data.dob_month.length && 0 < data.dob_day.length) {
          if (18 > calculateAge(new Date(`${data.dob_year}-${data.dob_month}-${data.dob_day}`))) {
            errorFields.push({
              key: 'dob',
              message: 'You must be at least 18 years old to register.'
            });
            setErrorFields(errorFields);
            reject();
            return;
          }
        } else {
          errorFields.push({
            key: 'dob',
            message: 'Please enter a valid date of birth.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'location':
        const findLocation = locations.filter((l) => l.id === data.location.id);
        if (0 === findLocation.length) {
          errorFields.push({
            key: 'location',
            message: 'Please select your location from the list provided.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'username':
        if (!/^[A-Za-z0-9_-]+$/.test(value) || !/[a-zA-Z]/g.test(value) || 0 === value.length) {
          errorFields.push({
            key: 'username',
            message: 'Please enter a valid ' + field.replace('_', ' ') + '.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'email':
        if (String(value).toLowerCase().match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        )) {
          resolve();
        } else {
          errorFields.push({
            key: 'email',
            message: 'Please enter a valid email address.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'password':
        if (value.length < 8) {
          errorFields.push({
            key: 'password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'confirm_password':
        if (value.length < 8) {
          errorFields.push({
            key: 'confirm_password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'confirm_password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (value !== data.password) {
          errorFields.push({
            key: 'confirm_password',
            message: 'Passwords do not match.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      default:
        // company can be auto validated (optional field)
        resolve();
        break;
    }
  });

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    if (0 !== data.dob_year.length && 0!== data.dob_month.length && 0!== data.dob_day.length) {
      newData['dob'] = `${data.dob_year}-${data.dob_month}-${data.dob_day}`;
    }

    setData(newData);
    setErrorFields([]);
  };

  const updateFieldById = (id, value) => {
    const newData = { ...data };
    newData[id] = value;

    setData(newData);
    setErrorFields([]);
  };

  const submit = () => {
    setLoading(true);
    validateFields().then(() => {
      Api.Account.register(data, locations).then((response) => {
        setLoading(false);

        if (undefined === response.violations) {
          setSuccessMessage(<>
            <div className={'mb-2 bold'}>Account created successfully!</div>
            Please <span className={'bold text-decoration-underline'}>activate</span> your account by clicking the link in the email we sent you.
            If you can't see the email, please check your spam / junk folders.
          </>);
        } else {
          response.violations.forEach((violation) => {
            if ('This value should not be blank.' === violation.message) return;

            setErrorFields([{
              key: violation.propertyPath,
              message: 'This value is already used.' === violation.message ? 'Error registering account' : violation.message
            }]);
          })
        }
      }).catch((e) => {
        setLoading(false);
        setErrorFields([{
          key: 'login',
          message: 'An error occurred, please try again later!'
        }])
        console.log('error', e);
      });
    }).catch((e) => {
      setLoading(false);
      console.log('error in validation', e);
    });
  };

  return <form id={'register_dialog'} onSubmit={(e) => e.preventDefault()} autoComplete={'off'}>
    {successMessage ? <Row>
      <Col className={'mt-3'}>
        <Alert variant={'success'}>
          {successMessage}
        </Alert>
      </Col>
    </Row> : <Row>
      <Col xs={12} md={6} className="mt-3">
        <label htmlFor="orientation" className="mb-1">
          <FontAwesomeIcon icon="venus-mars" className="me-2"/>
          I am
        </label>
        <fieldset>
          <select id={'orientation'} defaultValue={'select'} onChange={updateField}>
            <option value={'select'} disabled>Please select an option</option>
            <option value={'Man->Woman'}>A man looking for a women</option>
            <option value={'Women->Man'}>A women looking for a man</option>
            <option value={'Man->Man'}>A man looking for a man</option>
            <option value={'Women->Women'}>A women looking for a women</option>
          </select>
          {0 < errorFields.filter(field => 'orientation' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'orientation' === field.key)[0].message}
          </div> : 0 < data.orientation.length ? <div className={'text-success'}>
            <FontAwesomeIcon icon={'check'} className={'me-2'}/>

          </div> : ''}
        </fieldset>
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <fieldset>
          <label htmlFor="username" className="mb-1">
            <FontAwesomeIcon icon="user" className="me-2"/>
            Date of Birth
          </label>
          <div className={'d-flex'}>
            <input
              type="text"
              id="dob_day"
              name="dob_day"
              value={data.dob_day}
              onChange={updateField}
              placeholder="Day"
              onKeyDown={(e) => (48 > e.which || e.which > 57) ? (e.key.length === 1) ? e.preventDefault() : e : e}
              onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
              className={errorFields.includes('dob') ? styles.input__error : ''}
              autoComplete={'off'}
              pattern="[0-9]+"
              maxLength={2}
              required
            />
            <input
              type="text"
              id="dob_month"
              name="dob_month"
              value={data.dob_month}
              onChange={updateField}
              placeholder="Month"
              onKeyDown={(e) => (48 > e.which || e.which > 57) ? (e.key.length === 1) ? e.preventDefault() : e : e}
              onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
              className={errorFields.includes('dob') ? styles.input__error : ''}
              autoComplete={'off'}
              pattern="[0-9]+"
              maxLength={2}
              required
            />
            <input
              type="text"
              id="dob_year"
              name="dob_year"
              value={data.dob_year}
              onChange={updateField}
              placeholder="Year"
              onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
              className={errorFields.includes('dob') ? styles.input__error : ''}
              autoComplete={'off'}
              pattern="[0-9]+"
              maxLength={4}
              required
            />
          </div>
          {0 < errorFields.filter(field => 'dob' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'dob' === field.key)[0].message}
          </div> : 0 < data.dob.length ? <div className={'text-success'}>
            <FontAwesomeIcon icon={'check'} className={'me-2'}/>
            Age confirmed
          </div> : <div className={'text-danger'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            Over 18's only!
          </div>}
        </fieldset>
      </Col>
      <Col xs={12} className="mt-3">
        <fieldset>
          <label htmlFor="username" className="mb-1">
            <FontAwesomeIcon icon="globe-stand" className="me-2"/>
            Your location <i>(City / Town)</i>
          </label>
          <TextSearchField
            id={'location'}
            placeholder={'Enter your location'}
            hint={''}
            value={locationSearch}
            selection={data.location}
            choices={locations}
            onChange={(id, value) => setLocationSearch(value)}
            onSelection={updateFieldById}
            required={true}
          />
          {0 < errorFields.filter(field => 'location' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'location' === field.key)[0].message}
          </div> : ''}
        </fieldset>
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <fieldset>
          <label htmlFor="username" className="mb-1">
            <FontAwesomeIcon icon="user" className="me-2"/>
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={data.username}
            onChange={updateField}
            placeholder="Create a username"
            onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
            className={errorFields.includes('username') ? styles.input__error : ''}
            autoComplete={'on'}
            pattern="[A-Za-z0-9_-]*"
            required
          />
          {0 < errorFields.filter(field => 'username' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'username' === field.key)[0].message}
          </div> : ''}
        </fieldset>
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <fieldset>
          <label htmlFor="email" className="mb-1">
            <FontAwesomeIcon icon="envelope" className="me-2"/>
            Email address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={data.email}
            placeholder="i.e. me@example.com"
            onChange={updateField}
            className={errorFields.includes('email') ? styles.input__error : ''}
            autoComplete={'off'}
            required
          />
        </fieldset>
        {0 < errorFields.filter(field => 'email' === field.key).length ? <div className={'red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'email' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <fieldset>
          <label htmlFor="password" className="mb-1">
            <FontAwesomeIcon icon="lock" className="me-2"/>
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={data.password}
            onChange={updateField}
            placeholder="Enter a password"
            className={errorFields.includes('password') ? styles.input__error : ''}
            autoComplete={'off'}
            required
          />
        </fieldset>
        {0 < errorFields.filter(field => 'password' === field.key).length ? <div className={'red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'password' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <fieldset>
          <label htmlFor="confirm_password" className="mb-1">
            <FontAwesomeIcon icon="lock" className="me-2"/>
            Confirm Password
          </label>
          <input
            type="password"
            id="confirm_password"
            name="confirm_password"
            value={data.confirm_password}
            onChange={updateField}
            placeholder="Confirm your password"
            className={errorFields.includes('confirm_password') ? styles.input__error : ''}
            autoComplete={'off'}
            required
          />
        </fieldset>
        {0 < errorFields.filter(field => 'confirm_password' === field.key).length ? <div className={'red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'confirm_password' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} className="mt-3">
        <Row>
          <Col>
            <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
              <FontAwesomeIcon icon="pencil" className="me-2"/>
              {loading ? 'Loading...' : 'Create Account'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 text-center">
            Already have an account?
            <button type={'button'} onClick={openLogin} className="ms-2 transparent border-0 text-tertiary">
              Login
            </button>
          </Col>
        </Row>
      </Col>
    </Row>}
  </form>;
};

export default Register;
