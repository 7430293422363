import React from 'react';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Dialog from '../';
import Register from '../../components/Register';

import styles from './styles.module.scss';

const RegisterDialog = ({ closeDialog }) => {
  return <Dialog title={<>
    <FontAwesomeIcon icon="pencil" className="me-2" />
    Create Account
  </>} closeDialog={closeDialog}>
    <div className={styles.dialog}>
      <Register />
    </div>
  </Dialog>;
}

export default RegisterDialog;
