const MetaData = (path) => {
  const data = {
    '/': {
      title: `Welcome to Only Amour`,
      description: '',
    },
  };

  return data[path];
};

export default MetaData;
