class App {
  version = 1;
  dialogState = {
    login: false,
    register: false,
    recover: false,
  }

  getVersion = () => {
    return this.version ?? 0;
  }

  setDialogState = (key, state) => {
    this.dialogState[key] = state;
  }

  getDialogState = (key) => {
    return this.dialogState[key];
  }
}

export default App;
