import Request from '../system/Request';

const Location = {
  getByCountry: (country) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/locations?country=${country}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Location;
