import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Providers from "./system/Providers";
import ScrollToTop from './system/ScrollToTop';

import Layout from './pages/Layout';
import Loading from './pages/Loading';

const Home = lazy(() => import('./pages/Home'));
const NoPage = lazy(() => import('./pages/NoPage'));

function App() {
  return <Providers>
    <BrowserRouter>
      <ScrollToTop />
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </Providers>;
}

export default App;
